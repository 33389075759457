
if (document.querySelector('.loader')) {
    const loader = document.querySelector('.loader')
    const content = document.querySelector('html')
    window.addEventListener('load', () => {
        loader.style.opacity = "0"
        loader.style.transition = "all .7s ease"
        content.style.overflowY = "scroll"
        setTimeout(() => {
            loader.remove()
        },'2000')
    });
}